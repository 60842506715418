<template>
  <div class="d-flex flex-column flex-root h-100">
    <div
      class="login login-1 d-flex flex-column flex-lg-row flex-column-fluid bg-white"
      :class="{
        'login-signin-on': this.state == 'signin',
        'login-signup-on': this.state == 'signup',
        'login-forgot-on': this.state == 'forgot',
      }"
      id="kt_login"
    >
      <!--begin::Content-->
      <div
        class="login-content flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto"
      >
        <div class="d-flex flex-column-fluid flex-center">
          <!--begin::Signin-->
          <div class="login-form login-signin">
            <validation-observer ref="loginForm" #default="{ invalid }">
              <b-form class="auth-login-form mt-2">


                <!--begin::Heading-->
								<div class="text-center mb-15">
									<!--begin::Title-->
									<h1 class="text-dark fw-bolder mb-3 font-weight-bolder h1">Sign In</h1>
									<!--end::Title-->
									<!--begin::Subtitle-->
									<div class="text-gray-500 fw-semibold h6">Welcome to Eye Clinic</div>
									<!--end::Subtitle=-->
								</div>

                <!-- <div class="pb-13 pt-lg-0 pt-5">
                  <h3
                    class="font-weight-bolder text-dark text-center font-size-h4 font-size-h1-lg"
                  >
                    Welcome to Eye clinic
                  </h3>
                </div> -->
                <b-form-group
                  label-for="login-email"
                  label-class="font-size-h6 font-weight-bolder text-dark"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Email"
                    vid="email"
                    rules="required|email"
                  >
                    <b-form-input
                      id="login-email"
                      v-model="form.userEmail"
                      name="login-email"
                      :state="errors.length > 0 ? false : null"
                      class="p-6"
                      placeholder="Email"
                      @keyup.enter="login"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group
                  label-for="login-password"
                  label-class="font-size-h6 font-weight-bolder text-dark"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Password"
                    vid="password"
                    rules="required"
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid' : null"
                    >
                      <b-form-input
                        id="login-password"
                        v-model="form.password"
                        name="login-password"
                        class="p-6"
                        :type="passwordFieldType"
                        autocomplete="off"
                        placeholder="Password"
                        @keyup.enter="login"
                      />
                      <b-input-group-append is-text class="cursor-pointer">
                        <i
                          :class="passwordToggleIcon"
                          @click="togglePasswordVisibility"
                        ></i>
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-overlay
                  :show="loading"
                  opacity="1"
                  spinner-variant="white"
                  variant="primary"
                >
                  <b-button
                    variant="primary"
                    class="font-size-h6 font-weight-bolder py-3"
                    block
                    :disabled="invalid"
                    @click="login"
                  >
                    Sign in
                  </b-button>
                </b-overlay>
              </b-form>
            </validation-observer>
          </div>
          <!--end::Signin-->
        </div>

        <!--begin::Content footer-->
        <div
          class="d-flex justify-content-center text-dark py-7 py-lg-0"
        >
          <span>Copyright © {{ getYear }} - Eye Clinic</span>
        </div>
        <!--end::Content footer-->




        <!--begin::Content footer-->
        <!-- <div
          class="d-flex justify-content-lg-start justify-content-center align-items-end py-7 py-lg-0"
        >
          <a href="#" class="text-primary font-weight-bolder font-size-h5"
            >Terms</a
          >
          <a href="#" class="text-primary ml-10 font-weight-bolder font-size-h5"
            >Plans</a
          >
          <a href="#" class="text-primary ml-10 font-weight-bolder font-size-h5"
            >Contact Us</a
          >
        </div> -->
        <!--end::Content footer-->
      </div>
      <!--end::Content-->

      <!--begin::Aside-->
      <div
        class="login-aside d-flex flex-column justify-content-center flex-row-auto"
      >
        <div class="d-flex flex-column-auto flex-column">
          <img
              :src="appLogoImage"
              alt="logo Eye Clinic"
              class="login-logo"
            />
        </div>
      </div>
      <!--begin::Aside-->

    </div>
    <b-modal
      centered
      size="lg"
      v-model="conditionFacility"
      hide-footer
      no-close-on-backdrop
      no-close-on-esc
    >
      <b-form-group label="Choose a clinic" label-for="clinic">
        <treeselect
          v-model="facility"
          :options="optionsFacilities"
          :clearable="true"
          :normalizer="normalizer"
        >
        </treeselect>
      </b-form-group>
    </b-modal>
    <ToastNotification ref="toast" />
  </div>
</template>

<!-- Load login custom page styles -->
<style lang="scss">
@import "@/assets/sass/pages/login/login-1.scss";
</style>

<script>
import {
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BOverlay,
  BButton,
} from "bootstrap-vue";
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";

import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@/core/utils/validations/validations.js";

import ToastNotification from "@/components/ToastNotification";
import Logo from "@/components/Logo.vue";

import axiosU from "@/core/services/api/auth";

export default {
  name: "login-1",
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BOverlay,
    BButton,

    ToastNotification,
    Logo,

    ValidationProvider,
    ValidationObserver,
  },
  watch: {
    facility(newVal) {
      if (newVal && !this.onlyFacility) {
        this.changeRoute();
      }
    },
  },
  data() {
    return {
      state: "signin",
      // Remove this dummy login info
      form: {
        userEmail: "",
        password: "",
      },
      user: null,
      token: null,
      permissions: null,
      conditionFacility: false,
      facility: null,
      optionsFacilities: [],
      onlyFacility: false,
      sideImg: "",

      required,
      email,

      passwordFieldType: "password",
      loading: false,
      normalizer(node) {
        return {
          id: node.id,
          label: node.name,
        };
      },
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password"
        ? "fas fa-eye"
        : "fas fa-eye-slash";
    },
    appLogoImage() {
      return (
        process.env.BASE_URL + "media/logos/Recurso 6.png"
      );
    },
    getYear() {
      return new Date().getFullYear();
    },
  },
  methods: {
    togglePasswordVisibility() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
    },
    login() {
      this.loading = true;
      const { userEmail, password } = this.form;
      const decodedUser = {
        email: userEmail,
        password: btoa(password),
      };
      axiosU
        .loginUser(decodedUser)
        .then(({ user, type, permissions, token }) => {
          if (user != null) {
            this.user = user;
            this.token = token;
            this.permissions = permissions;
            this.optionsFacilities = user.facilities
            if (user.facilities.length === 1) {
              this.onlyFacility = true;
              this.changeRoute(user.facilities[0].id);
              this.facility = user.facilities[0];
            } else {
              this.conditionFacility = true;
            }
            localStorage.setItem(
              "setFacilityOptions",
              btoa(JSON.stringify(this.optionsFacilities))
            );
          }
          this.loading = false;
        }).catch(() =>{
          this.loading = false;
          this.$refs.toast.danger("User type not added");
        })
    },
    changeRoute(id) {
      const user = this.user;
      const token = this.token;
      const permisosTotal = [];
      const permissions = this.permissions;
      permissions.forEach((item) => {
        permisosTotal.push({
          action: item.split(".")[1],
          subject: item.split(".")[0],
        });
      });
      localStorage.setItem(
        "userData",
        JSON.stringify({
          ...user,
          role: user.roles[0].name,
          ability:
            user.roles[0].id === 1
              ? [
                  {
                    action: "manage",
                    subject: "all",
                  },
                ]
              : permisosTotal,
        })
      );
      localStorage.setItem("accessToken", token);
      const userData = JSON.parse(localStorage.getItem("userData"));
      this.$ability.update(userData.ability);
      let datos;
      if (id) {
        datos = {
          facilities_id: id,
        };
      } else {
        datos = {
          facilities_id: this.facility,
        };
      }
      axiosU.selectFacility(datos).then((res) => {
        localStorage.setItem("facility", res.facilities_id);
        localStorage.setItem("Facility", btoa(JSON.stringify(this.facility)));
        console.log(userData.roles[0].name);
        this.$router
          .replace({ name: "dashboard" })
          .catch(() => {});
      });
    },
  },
};
</script>
